<template>
  <div class="screen-condition">
    <fb-header slot="header"
               :title="title"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="back"
                 size="small"
                 type="primary">
        返回
      </fb-button>
    </fb-header>
    <div class="screen-condition-hull">
      <!--  三级联动+多选 -->
      <div v-show="loadingStart">
        <van-loading vertical />
      </div>
      <div v-for="(item,index) in screenConditionData"
           :key="'a'+index">
        <van-collapse v-model="activeNames[index]">
          <van-collapse-item :title='item.title'
                             @click="vanClickFn"
                             :name='item.num'>
            <!-- 展开显示内容-->
            <div v-for="(twoitem,twoindex) in item.data"
                 :key="'b'+twoindex"
                 class="screen-condition-data-for">
              <van-checkbox v-model="value1['a'+index+'-'+twoindex]">
                {{twoitem.value}}
                <!-- {{'a'+index+'-'+twoindex}} -->
              </van-checkbox>
              <div v-for="(threeitem,threeindex) in twoitem.child"
                   :key="'c'+threeindex">
                <van-checkbox v-model="value1['b'+index+'-'+twoindex+'-'+threeindex]">
                  &nbsp;&nbsp;{{threeitem.value}}
                  <!-- {{'b'+index+'-'+twoindex+'-'+threeindex}} -->
                </van-checkbox>
              </div>
            </div>
            <!--  -->
          </van-collapse-item>
        </van-collapse>
      </div>
      <div class="change-case-button">
        <van-button plain
                    @click="vanBtnClearFn"
                    color='#fff'
                    type="primary"><span style="color:#999999">清除</span></van-button>
        <van-button @click="vanBtnRetrieveFn"
                    type="primary"
                    color="#009FFC"><span style="color:#FFF">重新检索</span></van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'screenCondition',
  // props: ['screenConditionData'],
  data() {
    return {
      value1: {},
      title: '筛选',
      divshow: false,
      activeNames: [[], [], [], [], [], []], // 绑定折叠div
      checked: {},
      divshowdata: [],
      loadingStart: true
    }
  },
  computed: {
    ...mapState('ana', ['screenConditionData'])
  },
  watch: {
    screenConditionData(val) {
      if (val) {
        this.loadingStart = false
      }
    },
    value1: {
      deep: true,
      handler(val) {
        // 可以监控到选中取消无法回显数据  键值为a+num  取键值截取1-length为num 查找对应数据
        console.log(val)
        // 处理数据  只保留true   取键值  查找
        const newarr = [] // 选中的num
        for (var i in val) {
          if (val[i]) {
            newarr.push(i)
          }
        }
        // 根据num 去查找详细数据存到store
        // this.traverse(this.screenConditionData[0].data, i.substring(1))
        // 接口返回数据 num 不是唯一值  用字母+下标作为唯一值判断
        const arr = []
        newarr.forEach((item0, index0) => {
          this.screenConditionData.forEach((item, index) => {
            // 遍历去查找
            item.data.forEach((twoitem, twoindex) => {
              if ('a' + index + '-' + twoindex === item0) {
                arr.push({ key: twoitem.key, value: [twoitem.value] })
              }
              if (twoitem.child && twoitem.child[0]) {
                twoitem.child.forEach((threeitem, threeindex) => {
                  if (
                    'b' + index + '-' + twoindex + '-' + threeindex ===
                    item0
                  ) {
                    arr.push({ key: threeitem.key, value: [threeitem.value] })
                  }
                })
              }
            })
          })
        })
        console.log(arr)
        // 找到后存入store
        this.SET_SCREENSELECT(arr)
      }
    }
  },
  mounted() {
    var arr = Object.keys(this.screenConditionData)
    if (arr.length !== 0) {
      this.loadingStart = false
    }
    console.log(arr.length !== 0)
  },
  methods: {
    // 设置输入框的值  设置筛选选中
    ...mapMutations('ana', ['SET_CHANGECASEDATA', 'SET_SCREENSELECT']),
    back() {
      if (
        this.directGoNative ||
        window.nativeEnterIndex >= this.$router.length
      ) {
        console.log('直接返回到原生端')
        this.backToNative()
      } else {
        this.$router.back()
      }
    },

    vanBtnClearFn() {
      console.log('清除')
      console.log(this.selectData)
      this.value1 = []
    },
    vanBtnRetrieveFn() {
      console.log('重新检索')
      // 重新检索之后 传  name + id   传给ana之 ana拼接数据 展示
      // bus 修改index
      // go(-1) 返回上个页面
      // console.log(this.semanticData)
      // this.SET_CHANGECASEDATA(this.selectData)

      this.$router.go(-1)
    },
    vanClickFn() {
      console.log('点击了')
    }
  }
}
</script>

<style scope>
.screen-condition {
  margin-top: 45px;
}
.screen-condition-hull {
  display: flex;
  /* align-content: center; */
  /* justify-content: center; */
  flex-direction: column;
  /* border-bottom: 1px solid #f2f2f2; */
  /* flex-grow: 1; */
}
.screen-condition-territory {
  width: 50%;
  flex-grow: 1;
  text-align: center;
}
.screen-condition-tit {
  width: 50%;
  flex-grow: 1;
  text-align: center;
  line-height: 45px;
  font-size: 14px;
}

.screen-condition-center {
  display: flex;
  align-content: center;
  justify-content: center;
  border-bottom: 1px solid #f2f2f2;
}

.change-case-button {
  display: flex;
  margin-top: 45px;
}
.change-case-button > button:nth-child(1) {
  flex-grow: 4;
}
.change-case-button > button:nth-child(2) {
  flex-grow: 6;
}
</style>
